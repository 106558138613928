export const themeSettings = {
  palette: {
    primary: {
      main: 'rgba(69, 87, 117, 1)'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1498
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 28
        }
      }
    }
  },
  typography: {
    allVariants: {
      color: 'rgba(69, 87, 117, 1)'
    },
    fontFamily: ["'Source Sans Pro'", 'Arial', 'sans-serif'].join(',')
  }
};
