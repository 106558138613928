import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import Validators from 'redux-form-validators';
import { BrowserRouter } from 'react-router-dom';
import 'assets/nomalize.css';
import { AppRouter } from 'router/AppRouter';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material';
import 'swiper/css';
import 'swiper/css/navigation';
import { themeSettings } from 'theme';

const theme = createTheme(themeSettings);

Object.assign(Validators.messages, {
  presence: 'Campo obligatorio'
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Suspense fallback={<div />}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <AppRouter />
          </ThemeProvider>
        </StyledEngineProvider>
      </Suspense>
    </BrowserRouter>
  </React.StrictMode>
);
