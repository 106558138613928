import React from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';
import { AcceptPrivacyPolicyPage } from 'modules/acceptPrivacyPolicy/pages';

export const AppRouter = () => {
  return (
    <Routes>
      <Route path={'/:slug'} element={<AcceptPrivacyPolicyPage />} />
      <Route path={'/404'} element={<p>not found</p>} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
